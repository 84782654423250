import * as React from "react"
import axios from "axios";
import Calendar from 'rc-year-calendar';
import 'rc-year-calendar/locales/rc-year-calendar.ru';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

import Collapse from '@mui/material/Collapse';
import Moment from 'react-moment';
import * as moment from 'moment'

import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

let link = "";
if (typeof window !== 'undefined') {
    const queryParams = new URLSearchParams(window.location.search);
    link = queryParams.get('link') === null ? "" : queryParams.get('link');
}

const yearNow = moment(new Date()).format('YYYY');
const yearPrev = parseInt(yearNow);
const yearNext = parseInt(yearNow) + 1;

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openDialog: false,
            events: [],
            eventDate: false,
            alertEvent: false,
            alertEventData: [],
            link: "",
            checkLink: 0,
            modalTitle: "",
            showModalDate: true
        };
    }

    componentDidMount() {
        this.checkLink();
    }

    checkLink() {
        let formData = new FormData();
        formData.append(
            "link", link
        );
        axios({
            method: "POST",
            data: Object.assign(formData),
            url: `https://ermakova.su/api/checkLink.php`,
            responseType: 'json',
        })
            .then(res => {
                if (res.data) {
                    this.setState({checkLink: res.data});
                }
            })
            .catch(function (error) {
                console.log('Error', error.message);
            });
    }

    getEvents(year) {
        const formData = new FormData();
        formData.append('year', year);
        formData.append('link', link);
        return fetch(`https://ermakova.su/api/school.php`, {
            method: 'POST',
            body: formData
        })
            .then(result => result.json())
            .then(result => {
                if (result.alerts.length > 0) {
                    this.setState({alertEvent: true});
                    this.setState({alertEventData: result.alerts});
                }
                if (result.events) {
                    return result.events.map(r => ({
                        id: r.id,
                        startDate: new Date(r.startDate),
                        endDate: new Date(r.endDate),
                        name: r.name,
                        color: r.color,
                        yandex: r.yandex,
                        linkDetail: r.link_detail,
                        location: r.location,
                        description: r.description,
                    }));
                }
            });
    }

    openDialogDayAlerts(date) {
        this.setState({
            openDialog: true,
            events: this.state.alertEventData,
            eventDate: this.state.alertEventData[0].startDate,
            modalTitle: "Текущие и ближайшие мероприятия",
            showModalDate: false
        });
    }

    openDialogDayEvents(e) {
        this.setState({
            openDialog: true, events: e.events, eventDate: e.date,
            modalTitle: "Мероприятия и события на",
            showModalDate: true
        });
    }

    closeDialogDayEvents() {
        this.setState({openDialog: false});
    }

    handleDayEnter(e) {
        if (e.events.length > 0) {
            this.openDialogDayEvents(e)
        }
    }

    closeAlertEvent() {
        this.setState({alertEvent: false});
    }

    render() {
        return (
            <div>
                {
                    this.state.checkLink === 2 ?
                        <>
                            <Collapse in={this.state.alertEvent}>
                                <Stack spacing={2}>
                                    <Alert severity="success"
                                           sx={{
                                               margin: '0px 0px 15px 0px'
                                           }}
                                    >
                                        <AlertTitle><strong>Текущие и ближайшие мероприятия</strong></AlertTitle>
                                        {
                                            this.state.alertEventData.map((event, key) => {
                                                    return (
                                                        <Typography
                                                            key={key}
                                                            component="p"
                                                            style={{
                                                                color: "#000000",
                                                                fontSize: '0.9rem',
                                                                marginBottom: '5px'
                                                            }}
                                                        ><span
                                                            onClick={() => Promise.resolve(this.openDialogDayAlerts())}
                                                            style={{
                                                                textShadow: 'none',
                                                                backgroundImage: 'none',
                                                                fontSize: '0.9rem',
                                                                fontWeight: "bold",
                                                                cursor: 'pointer',
                                                                color: '#1976d2',
                                                                borderBottom: '3px solid',
                                                                borderBottomColor: event.color

                                                            }}
                                                        ><Moment
                                                            format="DD.MM.YYYY">{event.startDate}</Moment></span>&nbsp;
                                                            {
                                                                moment(event.startDate).format('HH:mm') === '00:00' ?
                                                                    'Весь день' :
                                                                    <>
                                                                        с <Moment
                                                                        format="HH:mm">{event.startDate}</Moment> по <Moment
                                                                        format="HH:mm">{event.endDate}</Moment>
                                                                    </>
                                                            } &mdash; <strong>{event.name}</strong></Typography>
                                                    )
                                                }
                                            )
                                        }
                                    </Alert>
                                </Stack>
                            </Collapse>
                            <Dialog
                                scroll={'paper'}
                                fullWidth={true}
                                maxWidth={'md'}
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                                open={this.state.openDialog}
                                onClose={() => Promise.resolve(this.closeDialogDayEvents())}
                            >
                                <DialogTitle id="form-dialog-title"
                                             style={{
                                                 marginRight: '30px',
                                                 textAlign: 'center'
                                             }}
                                >
                                    {this.state.modalTitle} {this.state.showModalDate && <strong><Moment
                                    format="DD.MM.YYYY">{this.state.eventDate}</Moment></strong>}
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => Promise.resolve(this.closeDialogDayEvents())}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                        }}
                                    >
                                        <CloseIcon/>
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                        {
                                            this.state.events.map((event, key) => {
                                                    return (
                                                        <Paper
                                                            sx={{
                                                                mb: 4,
                                                                p: 2
                                                            }}
                                                            key={key}
                                                            elevation={3}
                                                        >
                                                            <strong
                                                                style={{
                                                                    color: event.color
                                                                }}
                                                            >
                                                                {
                                                                    this.state.showModalDate ?
                                                                        moment(event.startDate).format('HH:mm') === '00:00' ?
                                                                            'Весь день' :
                                                                            <>
                                                                                <Moment
                                                                                    format="HH:mm">{event.startDate}</Moment> &#8594; <Moment
                                                                                    format="HH:mm">{event.endDate}</Moment>
                                                                            </>
                                                                    :
                                                                        moment(event.startDate).format('HH:mm') === '00:00' ?
                                                                        <>
                                                                            Весь день, <Moment
                                                                                format="DD.MM.YY">{event.startDate}</Moment> &#8594; <Moment
                                                                            format="DD.MM.YY">{event.endDate}</Moment>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Moment
                                                                                format="HH:mm">{event.startDate}</Moment>, <Moment
                                                                                format="DD.MM.YY" style={{color: 'black', fontWeight: 'normal'}}>{event.startDate}</Moment> &#8594; <Moment
                                                                            format="HH:mm">{event.endDate}</Moment>, <Moment
                                                                            format="DD.MM.YY" style={{color: 'black', fontWeight: 'normal'}}>{event.endDate}</Moment>
                                                                        </>
                                                                }
                                                            </strong>
                                                            <Typography
                                                                variant="p"
                                                                component="p"
                                                                sx={{
                                                                    fontSize: "1.8rem",
                                                                    fontWeight: "bold",
                                                                    margin: '10px 0px 0px 0px'
                                                                }}
                                                            >
                                                                <a target="_blank" href={'https://www.google.com/calendar/event?action=TEMPLATE&text='+event.name+'&dates='+moment(event.startDate).format('YYYYMMDDTHHmmssZ')+']/'+moment(event.endDate).format('YYYYMMDDTHHmmssZ')+']&details='+event.description+'&location='+event.location+'&trp=false&sprop=&sprop=name:'}>
                                                                    <img
                                                                        style={{
                                                                            height: 28,
                                                                            width: 28,
                                                                            margin: '0px 15px -4px 0px',
                                                                            textAlign: 'left'
                                                                        }}
                                                                        alt="Добавить в календарь"
                                                                        src="./calendar-google-add.png"
                                                                    /></a>
                                                                {event.name}
                                                            </Typography>
                                                            <Typography
                                                                variant="p"
                                                                component="p"
                                                                sx={{
                                                                    fontSize: "12px",
                                                                    margin: "15px 0px 15px 0px",
                                                                    fontStyle: "italic"
                                                                }}
                                                            >
                                                                {
                                                                    event.yandex !== null ?
                                                                        <Link href={event.yandex} underline="none"
                                                                              className="external"
                                                                              target="_blank">{event.location}</Link>
                                                                        :
                                                                        event.location
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                variant="p"
                                                                component="p"
                                                                sx={{
                                                                    fontSize: "14px"
                                                                }}
                                                                dangerouslySetInnerHTML={{__html: event.description}}/>
                                                            {
                                                                event.linkDetail !== null && event.linkDetail !== undefined ?
                                                                    <Typography
                                                                        variant="p"
                                                                        component="p"
                                                                        sx={{
                                                                            fontSize: "12px",
                                                                            margin: "5px 0px 5px 0px",
                                                                            fontStyle: "italic"
                                                                        }}
                                                                    ><Link href={event.linkDetail} underline="none"
                                                                           className="external"
                                                                           target="_blank">Подробнее на
                                                                        сайте организации</Link></Typography>
                                                                    :
                                                                    ""
                                                            }
                                                        </Paper>
                                                    )
                                                }
                                            )
                                        }

                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => Promise.resolve(this.closeDialogDayEvents())}
                                            style={{
                                                textShadow: 'none',
                                                backgroundImage: 'none',
                                                fontSize: '0.8rem',
                                                fontWeight: "400",
                                                textTransform: 'none',
                                                margin: '10px 0 0 0'
                                            }}
                                    >Закрыть</Button>
                                </DialogActions>
                            </Dialog>

                            <Calendar
                                language="ru"
                                minDate={new Date("01.01." + yearPrev)}
                                maxDate={new Date("12.31." + yearNext)}
                                dataSource={year => this.getEvents(year)}
                                onDayClick={e => this.handleDayEnter(e)}
                            />
                        </>

                        :

                        this.state.checkLink === 0 ?

                            <Box sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "20px"
                            }}>
                                <CircularProgress/>
                            </Box>
                            :
                            <Alert severity="error">
                                <AlertTitle><strong>Ссылка устарела!</strong></AlertTitle>
                                <Typography
                                    component="span"
                                    style={{
                                        color: "#000000",
                                        fontSize: '0.9rem',
                                    }}
                                >Получите новую ссылку в группе WhatsApp в шапке чата класса.</Typography>
                            </Alert>
                }

            </div>
        );
    }
}
export default App