import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './css/index.css';
import {YMInitializer} from 'react-yandex-metrika';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App/>
        <YMInitializer accounts={[91835881]}/>
    </React.StrictMode>
);
